import axios from 'axios'


const baseURL = process.env.NODE_ENV === 'dev' ? 'https://dev.api.zg.haidubooks.com/' : 'https://api.zg.haidubooks.com/'

const http = axios.create({
  baseURL: baseURL
})

http.interceptors.response.use(
  response => {
    const { data } = response
    return data
  }
)


export default http
