import http from './config'

// 获取活动详情
export function getActivityDetail(params) {
  return http({
    url: '/api/h5/activity/detail',
    method: 'get',
    params
  })
}

// 生成订单接口
export function orderCreate(data, headers) {
  return http({
    url: '/api/v1/pay/order/create',
    method: 'post',
    data,
    headers
  })
}

// 上报pv /uv
export function reportPvUv(data,headers) {
  return http({
    url: '/api/h5/report/behavior',
    method: 'post',
    data,
    headers
  })
}
